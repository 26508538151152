.product-item-delivery-note-pricelist-logo-vendors {
	width: 120px;
	height: 40px;
}

.note-edit-input-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;

	.material-icons {
		font-size: 18px;
		padding: 0.395rem;
		border-radius: 5px;
		border-bottom-left-radius: 0px;
		border-top-left-radius: 0px;
	}

	.save {
		background: #2c7be5;
		color: #fff;
	}

	.note-edit-input {
		width: 100%;
		border: none;
		background: transparent;
		border-radius: 5px;
		border-bottom-right-radius: 0px !important;
		border-top-right-radius: 0px !important;
		outline: none;

		&.active {
			border: 1px solid #2c7be5;
		}
	}
}

.image-preview-window {
	position: absolute;
	left: 5px;
	top: 5px;
	height: 0px;
	z-index: 100;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #fff;
	background-position: top center;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	border-radius: 3px;
	overflow: hidden;

	transition: opacity 0.5s, height 0.075s ease-in-out !important;

	&.normal {
		&.active {
			height: 200px;
		}
	}

	&.small {
		&.active {
			height: 150px;
		}
	}

	&.active {
		opacity: 1;
	}

	img {
		width: auto;
		height: 100%;
		border: 1px solid #6b6b6b;
	}
}

.table-header-collase-title-container {
	b {
		line-height: 1.8;
	}
}

.modal-header-container {
	display: flex;
	flex-direction: column !important;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	position: relative;

	.modal-title {
		width: 100%;
		padding-bottom: 1rem;
	}

	.modal-header-filter {
		width: 100%;
	}

	.btn-close {
		position: absolute;
		right: 20px;
		top: 20px;
	}
}

.modal-header-filter {
}

.badge-item-analog {
	position: relative;
	bottom: -12px;
	font-size: 11px;
	width: 100%;
	height: 0;
	text-align: center;
	left: 0;
	color: rgb(44, 123, 229);
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		padding: 5px;
		background: #fff;
	}
}

.search-items-container {
	position: relative;
	margin-right: 32px;
	display: inline-flex;
	max-width: 93%;

	.search-items-content {
		// overflow: auto;
		flex-wrap: wrap;
		padding-bottom: 1.5rem;
		min-width: 10px;
		min-height: 72px;
	}

	.filter-item-container {
		margin-right: 1rem;

		.filter-toggle {
			white-space: nowrap;
		}
	}

	.add-button-badgeItem {
		width: 32px;
		height: 32px;
		position: absolute;
		right: -55px;
		top: 0px;
	}
}

.box-cashback {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1px 3px;
	border-radius: 4px;
	box-shadow: 0 0px 2px rgba(0, 0, 0, 0.25);
	background: rgb(44, 123, 229) !important;
	color: #fff;
}

.circle-cashback {
	border-radius: 50%;
	font-size: 7px;
	background: rgb(44, 123, 229) !important;
	color: #fff;
	height: 14px;
	width: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 4px;

	.material-icons {
		font-size: 9px;
	}
}
.payment-checkbox {
	margin-bottom: 0 !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer !important;

	.form-check-input {
		width: 16px;
		height: 16px;
	}

	label {
		font-family: var(--falcon-body-font-family);
		cursor: pointer !important;
	}

	strong {
		line-height: 1.4;
	}

	.form-check-input[type='checkbox'] {
		position: relative;
		top: -2px;
		cursor: pointer !important;
	}

	.form-check-label {
		margin-bottom: 0;
		margin-left: 0.75em;
		cursor: pointer !important;
	}
}

.table-separator {
	display: flex;
	justify-content: center;
	flex-direction: row;
	border-bottom-width: 1px;
	border-bottom: 1px solid #dfdfdf !important;
	padding: 0.5em 0em !important;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.separator-line {
		margin: 1em;
		height: 2px;
		width: 100%;
		background: rgb(44, 123, 229) !important;
	}
}

.product-list-header-brand-title {
	line-height: 1.8;
}

.product-table {
	width: 100%;
	table {
		tbody {
			tr {
				// border-color: #2e75fe;
			}

			tr:last-child {
				// border-color: inherit;
			}
		}
	}
}

.product-header {
	position: sticky !important;
	z-index: 100;
	top: -170px;

	@media screen and (max-width: 1300px) {
		top: -215px;
	}

	.card-header-pills {
		margin: 0;
	}
}

.simplebar-mask {
	overflow: auto !important;
}

.simplebar-wrapper {
	overflow: auto !important;
	padding-bottom: 25px;
}

.cell-container {
	line-height: 1.6em;
	width: 100%;
}

.single-overflow-tooltip {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.multiline-overflow-tooltip {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.Products {
	.nav-fill {
		width: 100%;
	}

	.nav-item {
		cursor: pointer;
	}
}

.blue-color {
	color: #2e75fe;
}

.width-fill {
	width: 100%;
}

.nav {
	&.disabled {
		opacity: 0.5;

		.nav-item {
			a {
				cursor: default;
			}
		}
	}
}

.link-type-text {
	color: #2e75fe;
	text-decoration: underline;
	cursor: pointer !important;
}

.transform-uppercase {
	text-transform: uppercase;
}

.font-sm {
	font-size: 0.875rem;
}

.full-fill {
	width: 100%;
	height: 100%;
}

.image-placeholder {
	width: 64px;
	display: flex;
	flex-direction: column;
	aspect-ratio: 1 / 1;
	justify-content: center;
	align-items: center;
	border: 1px solid #dfdfdf;
	border-radius: 3px;
}

.tyre-image {
	.product-single-image {
		width: 64px;
		height: 64px;

		@media screen and (max-width: 1200px) {
			width: 48px !important;
			height: 48px !important;
		}
	}
}

.product-single-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #dfdfdf;
	border-radius: 3px;
	background-size: cover;
	background-position: top center;

	width: 64px;
	height: 64px;

	&.small {
		width: 32px !important;
		height: 32px !important;
	}
}

.analogs-modal {
	max-height: 60vh;
	overflow: auto;
	position: relative;

	.modal-header {
		position: sticky;
		top: 0;
		z-index: 100;
		background: #fff;
	}
}

.xl-size {
	.modal-dialog {
		padding: 2em;
		max-width: 1200px !important;
	}

	&.hidden {
		.modal-body {
			overflow: auto;
		}
	}
}

.small-size {
	.modal-dialog {
		padding: 2em;
		max-width: 700px !important;
	}

	&.hidden {
		.modal-body {
			overflow: auto;
		}
	}
}

.middle-size {
	.modal-dialog {
		padding: 2em;
		max-width: 900px !important;
	}

	&.hidden {
		.modal-body {
			overflow: auto;
		}
	}
}

.tooltip-inner {
	max-width: 450px !important;
}

.thumn-swiper {
	img {
		opacity: 0.5;
	}
}

.swiper-slide-thumb-active {
	img {
		opacity: 1;
	}
}

.card-header {
	.nav-pills .nav {
		&-link,
		&-link:focus,
		&-link:hover {
			border: 1px solid #2c7be5;
		}
	}
}
