.order-content {
	width: 100%;

	.simplebar-mask {
		overflow: visible !important;
	}
}

.collapsed-row-order {
	background: rgb(250, 250, 250);
	&.active {
		background: #fff;
	}
}

.simplebar-content-wrapper {
	overflow: visible !important;
}

.order-content-footer-container {
	height: 125px;
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap-reverse;
}

.buttons-container {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 400px;
	margin-right: 25px;

	button {
		min-width: 200px;
	}
}

.total-title {
	width: 100px;
}

.total-content {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
	width: 400px;

	.total-item {
		width: 150px;
		&.quantity {
			width: 100px;
		}
	}
}

.office-order-list-item {
	.alert {
		border: none;
	}
}
