.signup-field {
	padding-bottom: 1.75em;
	position: relative;

	.form-text {
		width: 100%;
		text-align: right;
		position: absolute;
		bottom: 0.5em;
	}
}

.eye-trigger {
	position: absolute;
	top: 5px;
	right: 10px;
	opacity: 0.5;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 1;
	}

	&.active {
		opacity: 1;
	}
}
