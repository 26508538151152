.custom-icon-navbar {
	width: 14px;
	height: auto;
	fill: #5e6f82;
	margin-right: 10px;
	position: relative;
	left: 4px;
}

.logo {
	&.large {
		font-size: 1.2em;
	}

	@media screen and (max-width: 600px) {
		display: none;
	}
}

.navbar-toggler-humburger-icon {
	.material-icons {
		font-size: 28px;
	}
	box-shadow: none !important;
	color: #696969 !important;

	margin-right: 1rem;

	@media screen and (max-width: 600px) {
		margin-right: 0rem;
	}

	&.active {
		color: var(--falcon-primary) !important;
	}
}

.navbar-brand {
	margin-right: 1.75rem !important;
}

.search-container {
	width: 100%;
}

.multi-search-button {
	background-color: var(--falcon-primary) !important;
	color: #fff !important;
	border-radius: 3px;

	span {
		max-width: 125px;
	}
}

.location-button {
	border-radius: 3px;
	background-color: var(--falcon-input-bg) !important;
	border: 1px solid var(--falcon-input-border-color) !important;
	box-shadow: var(--falcon-box-shadow-inset);

	span {
		max-width: 125px;
	}
}

.navbar-glass {
	background: transparent !important;
}

.navbar-top {
	max-width: 100% !important;
	margin-left: 0rem !important;
	margin-right: 0rem !important;
	position: absolute !important;
	top: 0;
	width: 100vw;

	@media screen and (max-width: 1200px) {
		left: 0em;
	}

	.menu-trigger {
		@media screen and (max-width: 1200px) {
			display: block;
		}
	}
}

.cart-circle-count {
	border-radius: 50%;
	background: #2c7be5;
	width: 17px;
	height: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	position: absolute;
	top: 9px;
	right: 0;
	border: 1px solid #edf2f9;
	font-size: 0.75em;
	line-height: 2;
}

.cart-icon-container {
	position: relative;
}

.nav-link-text {
	white-space: nowrap;
	display: block !important;
}

.navbar-vertical-divider {
	width: 100%;
}

.vertical-navbar-content {
	width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	height: calc(100vh - 4.3125rem);
	padding-top: 1rem;
	padding-bottom: 1.5rem;
}

.navbar-label {
	overflow: hidden;
	white-space: nowrap;
	flex-shrink: 0;
	margin-right: 1rem;
	color: var(--falcon-500);
	font-size: 0.75rem;
}

.navbar-vertical {
	position: sticky !important;
	top: 30px !important;
	margin-right: 0.75rem !important;

	@media screen and (max-width: 1200px) {
		position: absolute !important;
		max-width: calc(100% - var(--falcon-gutter-x, 1rem)) !important;
		left: var(--falcon-gutter-x, 1rem);
		z-index: 1000;
		min-height: 70vh;
		top: 4.3125rem !important;
	}

	.menu-trigger {
		position: absolute;
		top: -55px;

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
}
