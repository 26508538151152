.search-box {
	width: 100% !important;

	&.mobile {
		.react-select__control {
			padding-right: 30px;
		}
	}

	.react-select__control {
		padding: 0;
	}

	.react-select__value-container {
		height: 100%;

		width: 100%;
	}

	.react-select__placeholder {
		padding-left: 1rem !important;
	}

	.react-select__input-container {
		height: 100%;
		display: flex;
		width: 100%;

		.react-select__input {
			padding-left: 1rem !important;
		}
	}

	.dropdown-item {
		display: flex;
		white-space: break-spaces;
	}
}

.list-close-trigger {
	top: 7px;
	right: -2em;
	z-index: 101;
	border-radius: 3px;
	background-color: var(--falcon-input-bg) !important;
	border: 1px solid var(--falcon-input-border-color) !important;
	position: absolute;

	&:hover {
		box-shadow: var(--falcon-box-shadow-inset);
	}
}

.search-close-trigger {
	top: 7px;
	right: 10px;
	z-index: 101;
	border-radius: 3px;
	background-color: var(--falcon-input-bg) !important;
	border: 1px solid var(--falcon-input-border-color) !important;
	height: 100%;

	&:hover {
		box-shadow: var(--falcon-box-shadow-inset);
	}
}

.float-search-container {
	background-color: var(--falcon-body-bg);
	padding-right: var(--falcon-gutter-x, 1rem);
	position: absolute;
	width: 100%;
	top: -45px;
	left: 0;
	// transition: 0.3s ease-in-out;
	z-index: 100;

	&.active {
		transform: translateY(55px);
	}
}

.searchbox-custom-option {
	color: #111;
}

.spin {
	top: 35% !important;
	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.search-box-icon {
	&.spinner {
		top: 35% !important;
		animation-name: spin;
		animation-duration: 3000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}
