.text-transform-default {
	text-transform: unset !important;
	* {
		text-transform: unset !important;
	}
}

.pale {
	opacity: 0.5 !important;
}

.invert-contrast {
	filter: invert(1) contrast(100);
}

.hover-light {
	&:hover {
		filter: brightness(1.1) contrast(1.2);
	}
}

.arrow-collapse-container {
	position: relative;
	top: -4px;
}

.line-height-1-5 {
	line-height: 1.5 !important;
}

.arrow-collapse {
	position: relative;

	&.down {
		transform: rotate(-90deg);
	}
	&.up {
		top: 12px;
		transform: rotate(90deg);
	}
}

.popover {
	max-width: none !important;
}

.text-left {
	text-align: left !important;
}

.background-pale-color {
	background-color: #fafafa;
}

.square-icon {
	aspect-ratio: 1/1;
}

.text-decoration-none {
	text-decoration: none !important;
}

.no-transition-container {
	* {
		transition: none !important;
	}
}

.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}
.pagionation-wrapper {
	padding-bottom: 5rem;
}
.animated-list-wrapper {
}

.border {
	border: 1px solid rgb(215, 215, 215);
}

.border-right {
	border-right: 1px solid rgb(215, 215, 215);
}

.border-left {
	border-left: 1px solid rgb(215, 215, 215);
}

.form-control:disabled,
.form-control[readonly] {
	background-color: inherit !important;
	opacity: 1 !important;
}

.sticky-container {
	position: sticky;
	top: 1em;
}

.cursor-pointer {
	cursor: pointer !important;
}

.text-primary {
	&.hover {
		&:hover {
			filter: brightness(0.7) !important;
			text-decoration: underline;
		}
	}
}

.white-space-break-spaces {
	white-space: break-spaces !important;
}

.white-space-nowrap {
	white-space: nowrap !important;
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.shadow-big {
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;

	&.active {
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
	}
}

.shadow-small {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;

	&.active {
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;
	}
}

.color-red {
	color: rgb(180, 26, 26);
}

.color-green {
	color: rgb(0, 168, 11);
}

.color-grey {
	color: rgb(220 220 220);
}

.border-radius-0 {
	border-radius: 0px !important;
}

.brx-0 {
	border-left: 0px !important;
	border-right: 0px !important;
}

.fs-36 {
	font-size: 36px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-13 {
	font-size: 13.33333px !important;
}
.fs-12 {
	font-size: 12px !important;
}
.fs-10 {
	font-size: 10px !important;
}

.max-w-20 {
	max-width: 20%;
}

.max-w-30 {
	max-width: 30%;
}

.max-w-40 {
	max-width: 40%;
}

.max-w-50 {
	max-width: 50%;
}

.max-w-60 {
	max-width: 60%;
}

.max-w-70 {
	max-width: 70%;
}

.max-w-80 {
	max-width: 80%;
}
