.table-row-container {
	display: grid;
	grid-auto-flow: column;

	opacity: 1 !important;

	* {
		opacity: 1 !important;
	}

	&.highlight {
		background: rgb(204 246 228 / 44%);
	}
}
