.date-reset-trigger {
	position: absolute;
	right: 10px;
	z-index: 100;
}

.filter-button {
	transition: 0s !important;

	* {
		transition: 0s !important;
	}
}

.filter-preset-trigger {
	&.active {
		background: #2d7be62b;
		border-radius: 50%;
	}
	padding: 0.25em;
}

.search-filter-list {
	position: relative;
	height: 100%;

	.scroll-container {
		overflow: auto;
		height: calc(100% - 165px);
		margin-top: 115px;

		&.with_sort {
			height: calc(100% - 175px);
			margin-top: 125px;
		}
	}

	.footer {
		background: #fff;
		border-radius: 10px;
		border-top: 1px solid rgb(197, 197, 197);
		position: absolute;
		bottom: 0;
		height: 50px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.product-table-header-core-product {
	.filter-item-container {
		margin: 0 !important;
	}
}

.filter-input-container {
	.filter-input {
		color: #2c7be5;
		border: 1px solid #2c7be5;
		border-left: none;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;

		&.full-border {
			border: 1px solid #2c7be5 !important;
			border-radius: 3px !important;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: var(--falcon-200) !important;
			opacity: 1 !important;
		}

		&.active {
			color: #fff;
			border: 1px solid #2c7be5;
			background: #2c7be5;
		}
	}

	button {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	input {
		text-align: center;
		width: 45px;
		height: 29px;
		padding: 0;
	}
}

.filter-row {
	.filter-item-container {
		border-radius: 3px;
	}
}

.filter-item-container {
	.filter-toggle {
		border: 1px solid #dcdcdc;
		border-radius: 5px;
		position: relative;
		flex-direction: row;
		align-items: center;

		font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
			Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
			Segoe UI Symbol;

		.form-check-label {
			white-space: nowrap !important;
			margin: 0;
		}

		.form-check-input {
			cursor: pointer !important;
		}

		@media screen and (max-width: 575px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

.filter-containers {
	overflow: auto;
}

.desktop-filters,
.mobile-filters,
.product-map-active-list-content {
	.filter-item-container {
		.filter-toggle {
			.btn {
				padding: 0.91em;
			}
		}
	}
}

.filter-radio-group {
	flex-direction: row !important;
	align-items: center !important;
	flex-wrap: nowrap !important;

	@media screen and (max-width: 575px) {
		flex-direction: column !important;
		align-items: flex-start !important;
	}
}

.product-table-stock-list {
	.filter-toggle {
		border: 1px solid #2c7be5;
		max-width: 80vw;
	}
}

.form-control-label {
	font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
		Segoe UI Symbol !important;
	font-weight: 500;
}

.filter-scroll-container {
	overflow-x: auto;

	.filter-item-container {
		margin-bottom: 0;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-wrap: wrap;
	}
}

.filter-toggle-title {
	position: absolute;
	top: -1em;
	left: 1em;
	background-color: #fff;
	padding: 0.25em 1em;
}

.filter-item-container {
	position: relative;
	margin-bottom: 0;
	width: inherit;

	@media screen and (max-width: 768px) {
		margin-right: 0;
	}

	.cursor-pointer {
		label {
			cursor: pointer !important;
		}
	}
}

.filter-option-select-item {
	padding: 0.2rem;
	label {
		width: 100%;
		white-space: break-spaces;
	}
}

.dropdown-menu {
	position: relative;
	top: -2px !important;
}

.form-check-label {
	max-width: 550px;
}

.filter-toggle {
	min-width: 50px;
	max-width: 550px;

	@media screen and (max-width: 575px) {
		max-width: 100%;
	}

	.btn {
		text-align: left;
		overflow: hidden;
		height: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.filter-content-item {
	color: var(--falcon-dropdown-link-color);
	.form-check-label {
		cursor: pointer !important;
		font-weight: normal !important;
		margin-bottom: 0;
	}
}

.mobile-filters {
	display: none;
	@media screen and (max-width: 768px) {
		display: block;
	}
}

.accordion-filter {
	border: none;

	.accordion-item {
		border: none;
	}

	.accordion-header {
		border: none;
	}

	.accordion-button {
		border: none;
		padding-left: 1em;
		padding-right: 1em;

		font-size: 14px;
	}
}

.filter-option-check {
	.form-check-label {
		margin-bottom: 0;
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	}
}

.filter-label {
	position: absolute;
	top: -1.8em;
}

.filter-toggle-close-trigger {
	position: absolute;
	border-radius: 3px;
	background: rgb(198, 72, 72);
	color: #fff;
	top: -6.5px;
	right: -4px;
}

.header-filter-item {
	width: 100px;
	max-width: 150px;
	min-width: 100px;
}
