.table-company-requisites {
	.flex-table-table {
		&.no-border {
			border: none !important;

			.flex-table-row {
				border: none !important;
			}
			.flex-table-cell {
				border: none !important;
			}
		}
	}

	.flex-table-row:first-child {
		border-top: 1px solid rgb(121, 121, 121);
	}
	.flex-table-row:last-child {
		border-bottom: 1px solid rgb(121, 121, 121);
	}

	.flex-table-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
		border-bottom: 1px solid rgb(121, 121, 121);

		.flex-table-cell:first-child {
			width: 25%;
			min-width: 200px;
			border-left: 1px solid rgb(121, 121, 121);
			font-weight: bold;
		}
	}

	.flex-table-cell {
		width: 75%;
		border-right: 1px solid rgb(121, 121, 121);
		padding: 6px;

		div {
			display: inline-block;
		}
	}
}
