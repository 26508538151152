.tyre-tab-container {
	position: sticky;
}

.tyre-tab-wrapper {
	button {
		text-align: left !important;
		padding: 0.5rem 0;
	}
}

.list-image-map-popover {
	position: absolute;
}

.list-unit-image {
	background: #fcfefc;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 5px;
}

.list-quick-detail {
	min-width: 350px;

	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;

	&:hover {
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
	}
}

.list-unit-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 12px;
	gap: 12px;

	@media screen and (max-width: 1650px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: 1200px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.item-attribute-container {
	min-width: 300px;

	display: flex;
	justify-content: space-between;
	flex-direction: column;
	font-size: 14px;

	.item-attribute-content {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: row;

		.label {
			width: 50%;
			text-align: left;
			white-space: nowrap;
		}

		.text-value {
			width: 50%;
			text-align: left;
		}
	}
}

.list-detail-unit-table-cell {
	&.active {
		filter: invert(2);
	}
}

.list-image-map-item {
	&.active {
		border: 2px solid rgb(15, 107, 255);
	}
}

.list-quick-detail {
	.preview-image {
		min-width: 250px;
		cursor: pointer;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

		&:hover {
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
		}
	}
}

.catalog-container {
	width: 100%;
	// min-width: 1100px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	.alphabet-list-content {
		min-width: 700px;
	}

	.alphabet-list-catalog {
		width: 75%;
		overflow: auto;
	}

	.catalog-search-by-vin {
		width: 25%;
	}

	@media screen and (max-width: 900px) {
		flex-direction: column;

		.alphabet-list-catalog {
			width: 100%;
			margin-bottom: 1rem;
		}
		.catalog-search-by-vin {
			width: 100%;
		}
	}
}

.catalog__items {
	flex-wrap: wrap;
	gap: 2rem;
	column-count: 4;

	.list_catalog_container {
		padding-top: 1px;
	}

	p {
		margin-bottom: 0;
	}

	@media (max-width: 1110px) {
		column-count: 3;
	}
}

.list-catalog-item {
	position: relative;
	margin: 0;
	break-inside: avoid-column;
}

.model-badge {
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.catalog-tyre-list-container {
	top: 30px;
	position: sticky;
	min-width: 300px !important;
	min-height: 500px;
}

.catalog-model-list-container {
	min-width: 400px !important;
	min-height: 500px;
}

.tab-wrapper {
	display: flex;
	flex-direction: column;

	.nav-tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;

		.nav-item {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
}

.treeview-container {
	.treeview-children {
		padding-left: 2.5rem;
		padding-bottom: 0.5rem;
		padding-top: 0.25rem;
	}

	.toggle-trigger {
		margin-right: 0.5rem;
		font-size: 16px;
		transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

		&.open {
			transform: rotate(90deg);
		}
	}

	.treeview-text::before {
		content: '' !important;
	}

	li {
		list-style: none;
	}

	span {
		cursor: pointer;
	}
	p {
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 0.8333333333rem;
		color: var(--falcon-600);
		padding: 0.25rem 0;
		margin-bottom: 0;
		cursor: pointer;

		&.active {
			color: #e29e00 !important;

			&:hover {
				filter: brightness(1);
			}
		}

		&.link {
			text-decoration: underline;
			color: #2e75fe;
		}

		&:hover {
			filter: brightness(1.2);
		}
	}
}

.search-input {
	min-width: 300px;
	position: relative;

	.clear-trigger {
		position: absolute;
		right: 0px;
		top: 2px;

		.material-icons {
			font-size: 18px;
		}
	}
}

.highlight {
	background-color: transparent;
	font-weight: bold;
	color: currentColor;
	padding: 0 !important;
}
