.image-modal-swiper {
	.swiper-slide {
		aspect-ratio: 1 / 1;
		background-size: contain !important;
		background-position: center center !important;
		background-repeat: no-repeat !important;
		border: 1px solid rgb(233, 229, 229);
	}
}

.image-modal-thumb-swiper {
	.swiper-slide {
		aspect-ratio: 1 / 1;
		background-size: cover !important;
		background-position: center center !important;
		background-repeat: no-repeat !important;
		border: 1px solid rgb(233, 229, 229);
	}
}
