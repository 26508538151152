.order-product-name-container {
	width: 70%;
	min-width: 70%;
	font-size: 14px;

	div {
		width: 100%;
	}
}

.office-order-notes {
	border: 1px solid rgb(232, 232, 232) !important;
	background: rgb(245, 245, 245) !important;

	&.transparent {
		border: none !important;
		background: transparent !important;
	}
}

.delivery-option-container {
	.btn:first-child {
		margin-right: 1%;
	}
	.btn:last-child {
		margin-left: 1%;
	}
	.btn {
		@media screen and (max-width: 768px) {
			width: 100% !important;
			margin-bottom: 1em !important;
		}
	}

	@media screen and (max-width: 650px) {
		flex-wrap: wrap;
	}
}

.order-products-list {
	border-top: 1px solid rgb(232, 232, 232);
	border-bottom: 1px solid rgb(232, 232, 232);
}

.order-product-info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;

	div {
		width: 100%;

		span {
			width: 100%;
			text-align: right;
		}
	}
}

.order-image {
	height: 40px;
	width: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid rgb(232, 232, 232);
	background: url('../assets/img/image-holder.png');
	background-size: cover;

	img {
		width: 100%;
		height: auto;
	}
}

.info-container {
	background: #f6f6f9;
}

.quantity-btn-control {
	&.right {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
	&.left {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}
}
