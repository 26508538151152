.sidebar {
	.navbar-collapse {
		margin-top: 0 !important;
	}

	@media screen and (max-width: 1200px) {
		margin-top: 0rem !important;
		margin-left: 0em !important;
		padding-left: 0em !important;
	}
}
