.offcanvas-start {
	max-width: 615px !important;
	width: 90% !important;
}
.offcanvas-body {
	padding: 0 !important;
}

.office-passport-offcanvas-container {
	.btn-close {
		position: absolute;
		top: 1em;
		right: -2em;
		background-color: #fff;
	}
}

.product-list-filter-offcanvas-container {
	.btn-close {
		position: absolute;
		top: 1em;
		left: -3em;
		background-color: #fff;
	}
}

.offcanvas-header {
	position: absolute;
	z-index: 10;
	width: 100%;
	padding-right: 2rem !important;
}

.shop-contact {
	.accordion-body {
		width: 100%;
		max-width: 300px;
		padding: 1rem 0.9rem !important;
	}
}

.shop-map {
	top: 0;
	left: 0;
	width: 100%;
	height: 200px;
	position: relative;
	z-index: 0;

	.yandex-map {
		width: 100%;
		height: 200px;
	}
}

.rating-bars {
	width: 100%;
	max-width: 400px;
}

.rating-info {
	width: 100%;
}

.photo-gallery {
	.swiper {
		padding: 2em 0;
	}
	.swiper-pagination {
		bottom: 0;
	}
	.swiper-slide {
		aspect-ratio: 1 / 1;
		border: 1px solid rgb(233, 229, 229);
	}
}

.shop-gallery-item {
	background-size: cover !important;
	background-position: top center !important;
}

.Shop {
	position: relative;

	.shop-header {
		width: 100%;

		.shop-image {
			min-width: 80px;
			min-height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 80px;
				height: auto;
			}
		}
	}

	.dot-active {
		position: absolute;
		left: -10px;
		top: 12px;
	}

	.accordion-header {
		.accordion-button {
			padding: 0 !important;
			border: none !important;
			box-shadow: none !important;
			font-size: 14px;
		}
		border: none !important;
		box-shadow: none !important;
	}

	.accordion-button:focus {
		background: none !important;
		border: none !important;
		box-shadow: none !important;
	}

	.rating-content {
		.progress {
			height: 0.75em;
			width: 100%;
		}
	}
}

.metro-icon {
	width: 32px;
	height: 32px;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	padding: 2px 5px;
	border-radius: 50%;
	background-color: rgb(205, 219, 14);

	&.small {
		padding: 1px 3px;
	}
}
