.product-map {
	background: rgb(215, 215, 215);
	position: absolute;
	left: 0;
	width: 100%;
	top: 0em;
	z-index: -1;
	height: calc(100vh - 70px);
}

.filter-button-mobile {
	display: none !important;

	@media screen and (max-width: 768px) {
		display: flex !important;
	}
}

.product-map-filter-container {
	display: flex;
	justify-content: flex-end;
	right: 0;
	top: 65px;
	width: 100%;
	min-width: 250px;
	transition: 0.3s ease-in-out;
	z-index: 1;
	overflow: visible;
	transform: translateX(0%);

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	.filter-toggle {
		max-width: 100%;
	}

	.filter-item-container {
		width: 100%;
		max-width: 100%;
	}

	.card {
		overflow: visible !important;
	}

	.card-body {
		overflow: visible !important;
	}

	@media screen and (max-width: 768px) {
		position: absolute;
		transform: translateX(100%);

		&.active {
			transform: translateX(0%);
		}
	}
}

.product-map-active-list-container {
	display: flex;
	left: 0;
	top: 0;
	width: 33%;
	max-width: 475px;
	min-width: 475px;
	transform: translateX(calc(-100% - 16.5em));
	transition: 0.3s ease-in-out;
	z-index: 100;
	margin-left: 16.5em !important;
	padding: 1rem;
	padding-top: 1rem;
	height: calc(100vh - 190px);

	@media screen and (max-width: 1200px) {
		margin-left: 1em !important;
		height: calc(100vh - 100px);
		padding-top: 4rem;
	}

	@media screen and (max-width: 1000px) {
		min-width: 400px;
	}

	@media screen and (max-width: 768px) {
		width: 90%;
		min-width: 90%;
		margin-left: 0em !important;
	}

	&.active {
		transform: translateX(0%);
	}
}
.active-product-list-content-card-header {
	z-index: 1001;
	position: relative;
}

.active-product-list-content-card-body {
	overflow: auto;
	padding: 1rem !important;
	padding-bottom: 0 !important;

	@media screen and (max-width: 768px) {
		padding-top: 0rem !important;
	}
}

.product-map-active-list-content {
	width: 100%;
	height: 100%;
}

.product-search-map-container {
	padding-top: 1rem;
	padding-right: 1rem;

	position: absolute;
	z-index: 2;
	right: 0;
	width: 100%;
	height: 200px;

	.header-map-container-types-tab {
		width: 90%;
		max-width: 500px;
		margin-right: 10px;
		background-color: #fff;
		padding: 0.35rem;
		border-radius: 5px;

		.nav-link {
			background-color: rgb(248, 248, 248);
		}

		.nav-pills {
			padding: 0 !important;
		}
	}
}

.header-map-container {
	position: absolute;
	z-index: 2;
	width: 200px;
	right: 0;
	padding: 1em;

	.filter-item-container {
		margin: 0;
		border: none;
		margin-left: 1rem;
		background: #fff;

		.filter-toggle {
			border: none;

			margin: 0;
		}
	}
}

.navbar-vertical-collapsed {
	.navbar-vertical {
	}

	.header-map-container {
		padding-left: 5em;

		@media screen and (max-width: 1200px) {
			padding-left: 1em;
		}
	}

	.product-map-active-list-container {
		margin-left: 5em !important;

		@media screen and (max-width: 1200px) {
			margin-left: 1em !important;
		}

		@media screen and (max-width: 768px) {
			margin-left: 0em !important;
		}
	}
}

.search-results-map-yandex-map {
	height: calc(100vh - 70px);
	width: 100vw !important;
	position: absolute !important;
	top: 70px;
	left: 0;
	z-index: 1;
	transition: none !important;

	* {
		transition: none !important;
	}

	.cluster-marker {
		width: 40px;
		height: 40px;
		transform: translate(-20px, -20px);

		border: 2px solid #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.29);
		border-radius: 20px;

		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		color: #ffffff;

		background: #2c7be5;

		&.orange {
			background: #e57f2c !important;
		}
	}

	.place-marker-container {
		position: relative;
	}

	.place-marker-cost {
		position: absolute;
		background-color: white;
		border-radius: 2px;
		width: 125px;
		padding: 3px;
		line-height: 20px;
		text-align: center;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
		left: -60px;
		top: 13px;
		font-size: 12px;
		font-weight: bold;
	}

	.place-marker {
		position: absolute;
		top: -24px;
		left: -12px;
	}

	.map {
		display: flex;
		justify-content: center;
		margin-top: -39px;
		align-items: center;
		width: 100%;
		height: 100%;
		transition: height 0.3s ease-in-out;
		background-color: lightgray;
	}
}
