.main-container {
	position: relative;

	* {
		transition: 0.3s ease;
	}
}

.main-content {
	width: calc(100% - 14.5rem);
	overflow: auto;

	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.navbar-vertical-collapsed {
	.navbar-label {
		width: 0%;
		margin-right: 0rem;
		opacity: 0;
	}

	.navbar-vertical {
		z-index: -1;
	}

	.main-content {
		width: calc(100% - 4.125rem);

		@media screen and (max-width: 1200px) {
			width: 100%;
		}
	}

	.product-map-filter-container {
		padding-left: 6em;

		@media screen and (max-width: 1200px) {
			margin-left: 1em;
		}

		@media screen and (max-width: 1200px) {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}
}

.form-select-container {
	.form-select {
		padding-right: 2.5rem !important;
	}
}

.main-content-container {
	min-height: 100vh;
	width: 100%;
	position: relative;
	padding: 0 1rem;
	border-radius: 0.375rem;
	transition: 0.3s ease-in-out;
	display: flex;
	padding-top: 4.3rem;

	@media screen and (max-width: 1200px) {
		// overflow-x: auto;
	}

	.dropdown-menu {
		div {
			color: var(--falcon-dropdown-color);
			text-align: right;
			list-style: none;
			background-color: var(--falcon-dropdown-bg);
			background-clip: padding-box;
			border: 1px solid var(--falcon-dropdown-border-color);
			border-radius: 0.25rem;
		}
	}
}

.product-map-filter-container {
	padding-left: 17.5rem;

	@media screen and (max-width: 1000px) {
		padding-left: 0rem !important;
	}
}

.container {
	@media screen and (min-width: 576px) {
		max-width: 100% !important;
	}
}
